import React from "react";
import 'D:/Site Casa Amarela/projetocasa/src/styles/rules.css';
import Flian from 'D:/Site Casa Amarela/projetocasa/src/images/Flian Logo.jpeg'
import concurso from 'D:/Site Casa Amarela/projetocasa/src/images/concurso_redacao.jpeg'
import { useEffect } from "react";
import HomenageadoCSS from 'D:/Site Casa Amarela/projetocasa/src/styles/homenageado.module.css';
import NilceiaImagem from 'D://Site Casa Amarela//projetocasa//src//images//Nilcea Imagem.png'

const Redacao = () => {
  useEffect(() => {
    document.title = 'Apoiadores';

    const updateMenuText = () => {
      const width = window.innerWidth;
      const menuItemParticipate = document.querySelector('a[href="/participe"]');
      const menuItemContest = document.querySelector('a[href="/redacao"]');

      // Alterar texto do menu "Participe"
      if (width <= 768) {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe";
        }
      } else {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe da Festa";
        }
      }

      // Alterar texto do menu "Concurso de Redação"
      if (width <= 768) {
        if (menuItemContest) {
          menuItemContest.textContent = "Redação";
        }
      } else {
        if (menuItemContest) {
          menuItemContest.textContent = "Concurso de Redação";
        }
      }
    };

    // Atualizar o texto ao carregar a página
    updateMenuText();

    // Atualizar o texto quando a janela for redimensionada
    window.addEventListener('resize', updateMenuText);

    // Limpar o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateMenuText);
    };
  }, []);
    return(
      <div>
      <header>
      <img alt="" className="flianLogo" src={Flian}></img>

      <div className="navigation">
        <ul className="menu">
          <li className="menu-item"> <a href="/home"> Inicio </a></li>
          <li className="menu-item"> <a href="/apoiadores"> Apoiadores </a></li>
          <li className="menu-item"> <a href="/homenageado"> Homenageado </a></li>
          <li className="menu-item"> <a href="/participe"> Participe da Festa </a></li>
          <li className="menu-item"> <a href="/redacao"> Concurso de Redação </a></li>
        </ul>
      </div>

      </header>
  <section className="programacoes-container">
  <div className="vertical-divider"></div>

        <section className={HomenageadoCSS.floatContainer}>
        <section className={HomenageadoCSS.floatChild}>
          <section className="nilceia-textos">
          <h1>Concurso de Redação da FliAn / Prêmio Nilcéa Clara Cardoso</h1>

          <p>Nilcéa Clara Cardoso, homenageada neste concurso, é uma figura de grande relevância na educação do bairro de Anchieta. Ao longo de sua carreira, atuou como Professora, Diretora e Supervisora Pedagógica, deixando um legado marcante nas instituições em que trabalhou. Além disso, Nilcéa cursou Direito e contribuiu significativamente nas áreas pedagógica e administrativa das escolas. Este concurso é uma forma de celebrar e honrar seu precioso legado, reconhecendo a importância de sua dedicação e impacto na comunidade.</p>

          <p>O Concurso de Redação/Prêmio Nilcéa Clara Cardoso é uma iniciativa da Festa Literária de Anchieta, em parceria com o Instituto Ciclos Brasil e a Biblioteca A Casa Amarela.</p>

          <p>Este concurso tem como objetivo incentivar a criatividade e o talento dos participantes, promovendo a expressão escrita e a valorização da literatura em nossa comunidade.
          Aberto a alunos e moradores do bairro de Anchieta, o concurso visa engajar a comunidade local, oferecendo uma plataforma para que todos possam compartilhar suas ideias e habilidades literárias.</p>

          <p>Os participantes concorrerão a prêmios incríveis, e três vencedores serão selecionados para receber um reconhecimento especial. Além disso, dez participantes serão agraciados com menções honrosas por sua contribuição.</p>

          <p>Não perca esta oportunidade única de participar do Concurso de Redação/Prêmio Nilcéa Clara Cardoso. É o momento de demonstrar seu talento literário e se destacar na comunidade de Anchieta e arredores. Mostre sua criatividade e habilidades de escrita, e quem sabe você poderá ser um dos vencedores deste prestigiado concurso.</p>

          <p>Leia atentamente as regras e Boa sorte!</p>
          <div className="botaoContainer">
            <a className="glow-on-hover" href="https://docs.google.com/forms/d/e/1FAIpQLSfF_yH5CsXbkvUjQRMkikDJM8jMtH5MgrXCWiHVC326YR5EKg/viewform">Participe do Concurso</a> 
            </div>
          </section>
        </section>
        

        <section className={HomenageadoCSS.floatChild1}>
        <section className="image">
        <img
          src={NilceiaImagem}
          alt="Nilceia"
          style={{ width: '100%', height: 'auto' }}  // Inline styles podem ser usados em conjunto com o CSS externo
        />
      </section>
        </section>
      </section>

</section>
      </div>
    )
}

export default Redacao