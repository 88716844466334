import React from "react";
import 'D:/Site Casa Amarela/projetocasa/src/styles/rules.css';
import Flian from 'D:/Site Casa Amarela/projetocasa/src/images/Flian Logo.jpeg'
import participe from 'D:/Site Casa Amarela/projetocasa/src/images/participedoEvento.jpeg'
import { useEffect } from "react";


const Participe = () => {
  useEffect(() => {
    document.title = 'Apoiadores';

    const updateMenuText = () => {
      const width = window.innerWidth;
      const menuItemParticipate = document.querySelector('a[href="/participe"]');
      const menuItemContest = document.querySelector('a[href="/redacao"]');

      // Alterar texto do menu "Participe"
      if (width <= 768) {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe";
        }
      } else {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe da Festa";
        }
      }

      // Alterar texto do menu "Concurso de Redação"
      if (width <= 768) {
        if (menuItemContest) {
          menuItemContest.textContent = "Redação";
        }
      } else {
        if (menuItemContest) {
          menuItemContest.textContent = "Concurso de Redação";
        }
      }
    };

    // Atualizar o texto ao carregar a página
    updateMenuText();

    // Atualizar o texto quando a janela for redimensionada
    window.addEventListener('resize', updateMenuText);

    // Limpar o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateMenuText);
    };
  }, []);
    return(
      <div>
      <header>
      <img alt="" className="flianLogo" src={Flian}></img>

      <div className="navigation">
        <ul className="menu">
          <li className="menu-item"> <a href="/home"> Inicio </a></li>
          <li className="menu-item"> <a href="/apoiadores"> Apoiadores </a></li>
          <li className="menu-item"> <a href="/homenageado"> Homenageado </a></li>
          <li className="menu-item"> <a href="/participe"> Participe da Festa </a></li>
          <li className="menu-item"> <a href="/redacao"> Concurso de Redação </a></li>
        </ul>
      </div>

      </header>


        <section className="programacoes-container">
  <section className="programacoes">
    <div className="participe-programacoes">
      <img className="responsive-img" src={participe} alt="Participe da FliAn" />
      <h>Bem-vindo ao formulário de inscrição para participar da 1ª Festa Literária de Anchieta (FLiAn). Este é o seu espaço para se conectar com leitores, compartilhar suas obras e contribuir para a celebração da literatura e cultura.</h>
      <h1>Categoria de Participação:</h1>
      <b><h1>1. Espaço Interno da Biblioteca:</h1></b>
      <p>Conversa com o Autor: Sessões de autógrafos e bate-papo com leitores.</p>
      <p>Rodas de Conversa: Participação em mesas redondas para debates literários.</p>

      <b><h1> 2. Espaço Externo - Feira dos Livros - Barraca para Venda de Livros:</h1></b>
      <p>Espaço destinado à venda de suas obras. Cada barraca será ocupada por dois autores, e a organização e disposição serão estipuladas pela organização do evento. O valor da venda dos livros ficará integralmente com o autor. O autor será responsável pelo custo de aluguel da barraca no valor de R$ 25,00. Este valor deve ser pago até o dia 15 de setembro de 2024.</p>
      <b><p>Certificado de Participação: </p></b>
      <p>Todos os autores participantes receberão um certificado de participação, reconhecendo sua contribuição para a FliAn 2024.</p>


      <b><p>Termos e Condições:</p></b>

      <p>O autor é responsável pela organização e disposição de seus materiais na barraca, incluindo a venda dos livros.</p>

      <p>O valor do aluguel da barraca deve ser pago via PIX até a data estipulada.</p>

      <p>Em caso de desistência, o valor do aluguel da barraca não será reembolsado.</p>

      <p>A participação no espaço interno está sujeita à confirmação pela organização do evento, considerando a disponibilidade de vagas e relevância das obras inscritas.</p>

      <p>Ao submeter a inscrição, você confirma a veracidade das informações fornecidas e concorda com os termos e condições estabelecidos pela organização da FliAn.</p>




    </div>
    <div className="botaoContainer">
      <a className="glow-on-hover" href="https://docs.google.com/forms/d/e/1FAIpQLSeQARdZih5PttdVkT61zfCyOrK3er8lMtL_8PJ-x6jR8sW81Q/viewform">Participe da Festa</a> 
    </div>
  </section>

  <div className="vertical-divider"></div>
</section>
      </div>
    )
}

export default Participe