import React from "react";
import Flian from 'D:/Site Casa Amarela/projetocasa/src/images/Flian Logo.jpeg'
import Ciclos from 'D:/Site Casa Amarela/projetocasa/src/images/LOGO CICLOS.jpeg'
import BCA_LOGO from 'D:/Site Casa Amarela/projetocasa/src/images/LOGO BCA_1.png'
import FLIAN_Logo from 'D:/Site Casa Amarela/projetocasa/src/images/Flian.png'
import { useEffect } from "react";

const Apoiadores = () => {
  useEffect(() => {
    document.title = 'Apoiadores';

    const updateMenuText = () => {
      const width = window.innerWidth;
      const menuItemParticipate = document.querySelector('a[href="/participe"]');
      const menuItemContest = document.querySelector('a[href="/redacao"]');

      // Alterar texto do menu "Participe"
      if (width <= 768) {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe";
        }
      } else {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe da Festa";
        }
      }

      // Alterar texto do menu "Concurso de Redação"
      if (width <= 768) {
        if (menuItemContest) {
          menuItemContest.textContent = "Redação";
        }
      } else {
        if (menuItemContest) {
          menuItemContest.textContent = "Concurso de Redação";
        }
      }
    };

    // Atualizar o texto ao carregar a página
    updateMenuText();

    // Atualizar o texto quando a janela for redimensionada
    window.addEventListener('resize', updateMenuText);

    // Limpar o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateMenuText);
    };
  }, []);
    return(
    <div className="about">
      <header>
      <img alt="flianLogo" className="flianLogo" src={Flian}></img>

      <div className="navigation">
        <ul className="menu">
          <li className="menu-item"> <a href="/home"> Inicio </a></li>
          <li className="menu-item"> <a href="/apoiadores"> Apoiadores </a></li>
          <li className="menu-item"> <a href="/homenageado"> Homenageado </a></li>
          <li className="menu-item"> <a href="/participe"> Participe da Festa </a></li>
          <li className="menu-item"> <a href="/redacao"> Concurso de Redação </a></li>
        </ul>
      </div>

      </header>
        <div class="fixed-bar">
          <p>Apoiadores</p>
        </div>

        <section className="LogosApoiadores">
            <img alt="Logo Ciclos" src={Ciclos}  style={{ width: '200px', height: '200px' }}></img>
            <img alt="Logo BCA" src={BCA_LOGO}  style={{ width: '200px', height: '200px' }} ></img>
        </section>

        <section className="LogoFLIAN">
        <img alt="Logo FLIAN" className="imagem-flian" src={FLIAN_Logo} />
      </section>

    </div>
    )
}

export default Apoiadores