import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Apoiadores from "./pages/apoiadores";
import Homenageado from "./pages/homenageado"
import Home from "./pages/home";
import Participe from "./pages/rules";
import Redacao from "./pages/redacao";

const AppRoutes = () => {
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/home" element={<Home/>}></Route>
                <Route path="/apoiadores" element={<Apoiadores/>}></Route>
                <Route path="/participe" element={<Participe/>}></Route>
                <Route path="/homenageado" element={<Homenageado/>}></Route>
                <Route path="/redacao" element={<Redacao/>}></Route>                
            </Routes>
        </Router>
    )
}

export default AppRoutes;