import React from "react";
import Card from 'D:/Site Casa Amarela/projetocasa/src/images/Flian.png'
import Flian from 'D:/Site Casa Amarela/projetocasa/src/images/Flian Logo.jpeg'
import image1 from 'D:/Site Casa Amarela/projetocasa/src/images/image1.jpeg'
import image2 from 'D:/Site Casa Amarela/projetocasa/src/images/image2.jpeg'
import image3 from 'D:/Site Casa Amarela/projetocasa/src/images/image3.jpeg'
import image4 from 'D:/Site Casa Amarela/projetocasa/src/images/image4.jpeg'
import { useEffect } from "react";


const Home = () => {
  useEffect(() => {
    document.title = 'Apoiadores';
    const updateMenuText = () => {
      const width = window.innerWidth;
      const menuItemParticipate = document.querySelector('a[href="/participe"]');
      const menuItemContest = document.querySelector('a[href="/redacao"]');

      // Alterar texto do menu "Participe"
      if (width <= 768) {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe";
        }
      } else {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe da Festa";
        }
      }

      // Alterar texto do menu "Concurso de Redação"
      if (width <= 768) {
        if (menuItemContest) {
          menuItemContest.textContent = "Redação";
        }
      } else {
        if (menuItemContest) {
          menuItemContest.textContent = "Concurso de Redação";
        }
      }
    };

    // Atualizar o texto ao carregar a página
    updateMenuText();

    // Atualizar o texto quando a janela for redimensionada
    window.addEventListener('resize', updateMenuText);

    // Limpar o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateMenuText);
    };
  }, []);
  return (
    <div className="home">
      <header>
      <img alt="" className="flianLogo" src={Flian}></img>

      <div className="navigation">
        <ul className="menu">
          <li className="menu-item"> <a href="/home"> Inicio </a></li>
          <li className="menu-item"> <a href="/apoiadores"> Apoiadores </a></li>
          <li className="menu-item"> <a href="/homenageado"> Homenageado </a></li>
          <li className="menu-item"> <a href="/participe"> Participe da Festa </a></li>
          <li className="menu-item"> <a href="/redacao"> Concurso de Redação </a></li>
        </ul>
      </div>

      </header>

      <div className="container-imagem">
        <img alt="" className="imagem" src={Card} ></img>
      </div>

      <div className="texto-central">
        <h1 className="cardo-regular">Livros podem mudar a sua vida!</h1>
      </div>

      <section className="float-container">
        <section className="float-child1">
          <section className="textos">

          <h1>Bem-vindos à FLiAn - Festa Literária de Anchieta</h1>

          <p>
          A FLiAn, Festa Literária de Anchieta, celebra a literatura e a cultura em sua primeira edição, que acontecerá no dia 21 de setembro de 2024, na Praça Nazaré, no bairro de Anchieta, Rio de Janeiro. Nosso objetivo é promover a leitura, valorizar escritores locais e estimular o hábito de ler em todas as idades.
          </p>

          <h1>Homenageado</h1>

          <p>
          Nesta edição, teremos a honra de homenagear o renomado autor Júlio Emílio Braz, cujas obras têm encantado leitores de todas as idades ao longo dos anos. Sua contribuição para a literatura infantojuvenil será celebrada com carinho e reconhecimento.
          </p>

          <h1>Concurso de Redação</h1>
          <p>
          Um dos destaques do evento é o concurso de redação, que premiará o vencedor com o prestigioso Prêmio Professora Nilcéia, incentivando novos talentos literários na região.
          </p>

          <h1>Apoio</h1>
          <p>
          A realização da FLiAn é possível graças ao apoio do Instituto Ciclos Brasil e da Biblioteca A Casa Amarela, instituições comprometidas com a disseminação do conhecimento e o incentivo à leitura.
          </p>

          <h1>Participe e Celebre Conosco!</h1>
          <p>
          Venha fazer parte deste evento único e inspirador. A FLiAn é uma oportunidade para mergulhar no universo da literatura, conhecer novos autores, participar de debates enriquecedores e se divertir com atividades culturais. Junte-se a nós e celebre a magia das palavras!
          </p>

          </section>
        </section>

        <section className="float-child">
          <section className="imagens">
          <img className="responsive-img" src={image1} alt="" />
          <img className="responsive-img" src={image2} alt="" />
          <img className="responsive-img" src={image3} alt="" />
          <img className="responsive-img" src={image4} alt="" />
          </section>
        </section>
      </section>

    <div class="icon-bar">
      <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
      <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
      <a href="#" className="whatsapp"><i className="fa fa-whatsapp"></i></a>
    </div>

    </div>
  );
}

export default Home;
