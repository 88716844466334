import React from "react";
import Flian from 'D:/Site Casa Amarela/projetocasa/src/images/Flian Logo.jpeg'
import HomenageadoImagem from 'D:/Site Casa Amarela/projetocasa/src/images/Livros/Homenageado.png'
import HomenageadoCSS from 'D:/Site Casa Amarela/projetocasa/src/styles/homenageado.module.css';
import livro1 from 'D:/Site Casa Amarela/projetocasa/src/images/Livros/1.png';
import livro2 from 'D:/Site Casa Amarela/projetocasa/src/images/Livros/2.png';
import livro3 from 'D:/Site Casa Amarela/projetocasa/src/images/Livros/3.png';
import { useEffect } from "react";



const Homenageado = () => {
  useEffect(() => {
    document.title = 'Apoiadores';

    const updateMenuText = () => {
      const width = window.innerWidth;
      const menuItemParticipate = document.querySelector('a[href="/participe"]');
      const menuItemContest = document.querySelector('a[href="/redacao"]');

      // Alterar texto do menu "Participe"
      if (width <= 768) {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe";
        }
      } else {
        if (menuItemParticipate) {
          menuItemParticipate.textContent = "Participe da Festa";
        }
      }

      // Alterar texto do menu "Concurso de Redação"
      if (width <= 768) {
        if (menuItemContest) {
          menuItemContest.textContent = "Redação";
        }
      } else {
        if (menuItemContest) {
          menuItemContest.textContent = "Concurso de Redação";
        }
      }
    };

    // Atualizar o texto ao carregar a página
    updateMenuText();

    // Atualizar o texto quando a janela for redimensionada
    window.addEventListener('resize', updateMenuText);

    // Limpar o evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateMenuText);
    };
  }, []);
    return(
        <div className="homenageado">
      <header>
      <img alt="" className="flianLogo" src={Flian}></img>

      <div className="navigation">
        <ul className="menu">
          <li className="menu-item"> <a href="/home"> Inicio </a></li>
          <li className="menu-item"> <a href="/apoiadores"> Apoiadores </a></li>
          <li className="menu-item"> <a href="/homenageado"> Homenageado </a></li>
          <li className="menu-item"> <a href="/participe"> Participe da Festa </a></li>
          <li className="menu-item"> <a href="/redacao"> Concurso de Redação </a></li>
        </ul>
      </div>

      </header>
      
        <div class="fixed-bar">
          <p>Homenageado</p>
        </div>
        <section className={HomenageadoCSS.floatContainer}>
        <section className={HomenageadoCSS.floatChild}>
          <section className="textos">
          <p>
          Júlio Emílio Braz nasceu em 16 de abril de 1959, na pequena cidade de Manhumirim, aos pés da Serra de Caparaô, Minas Gerais. Aos cinco anos mudou-se para o Rio de Janeiro, cidade que adotou como lar. É considerado autodidata, por aprender as coisas com extrema facilidade. Adquiriu o hábito da leitura aos seis anos. Iniciou sua carreira como escritor de roteiros para histórias em quadrinhos publicadas no Brasil e em outros países, como Portugal, Bélgica, França, Cuba e Estados Unidos.
          </p>

          <p>
          Começou publicando histórias em quadrinhos e, mais tarde, escreveu narrativas de bang-bang, sob 39 pseudônimos diferentes. Já lançou mais de 150 títulos. Em 1988, recebeu o Prêmio Jabuti pela publicação de seu primeiro livro infantojuvenil: Saguairu. Dois anos mais tarde, escreveu roteiros para o programa Os Trapalhões, da TV Globo, além de algumas mininovelas para uma emissora de televisão do Paraguai. Em 1997, ganhou o Austrian Children Book Award, na Áustria, pela versão alemã do livro Crianças na escuridão (Kinder im Dunkeln), e o Blue Cobra Award, no Swiss Institute for Children’s Book.
          </p>

          <p>
          A escolha de Júlio Emílio Braz como homenageado da primeira edição da FliAn não poderia ser mais apropriada. Sua vasta contribuição para a literatura infantojuvenil, marcada por uma habilidade única de criar narrativas envolventes e personagens cativantes, tem inspirado gerações de leitores. Além disso, sua trajetória como escritor autodidata e seu trabalho reconhecido internacionalmente refletem a essência da FliAn: promover a leitura, valorizar escritores talentosos e estimular o gosto pela literatura em todas as idades. Celebrar Júlio Emílio Braz é reconhecer o poder transformador das palavras e a importância da literatura na formação cultural e intelectual de nossa comunidade.
          </p>


          </section>
        </section>

        <section className={HomenageadoCSS.floatChild1}>
        <section className="image">
        <img
          src={HomenageadoImagem}
          alt="Homenageado"
          style={{ width: '100%', height: 'auto' }}  // Inline styles podem ser usados em conjunto com o CSS externo
        />
      </section>
        </section>
      </section>
       
      <section className="obrasContainer">
  <section className="livros-homenageado">
    <h1>Suas Grandes Obras:</h1>
    <div className="obra">
      <img className="obras" src={livro1} alt="livro1" />
      <p>Crianças na Escuridão</p>
    </div>
    <div className="obra">
      <img className="obras" src={livro2} alt="livro2" />
      <p>Sanguairu</p>
    </div>
    <div className="obra">
      <img className="obras" src={livro3} alt="livro3" />
      <p>Jovens Náufragos e Suas Batalhas</p>
    </div>
  </section>
  </section>




    </div>
    )
}

export default Homenageado