import './App.css';
import AppRoutes from './Routes';


function App() {
  return (
    <div>
      <AppRoutes/>
    </div>
  );
}

export default App;
